*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	list-style: none;
	box-sizing: border-box;
	list-style-type: none;
}
body {
	font-family: sans-serif;
	font-size: 1.2rem;
	font-weight: normal;
	line-height: 1.6;
	color: #252a32;
}

.sideMenu {
	left: 0;
	height: 100%;
	width: 260px;
	position: fixed;
	background: rgb(249, 249, 249);
	transition: all 0.35s ease-out;
}
.sideMenu a:hover {
	color: rgb(40, 0, 148);
}
